import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "./header"
import Footer from "./footer"
import img from "../images/cyber-health-check.jpg"

const CyberHealthCheck = () => {
    return (
        <div>
            <Header  pageTitle="Cyber Health Check"/>
            <section className="py-5 px-md-5 mx-xl-5">
                <Container>
                    <Row>
                        <Col md="6">
                            <h2 className="text-theme">Cyber Health Check (CHC)</h2>
                            <p className="my-2">CHC is a highly customizable, easy-to-use,
                            cloud-based cybersecurity audit tool. It is the perfect auditor’s toolbox. CHC allows
                            organizations and auditors to do away with cumbersome, error-prone, complicated Microsoft Excel-based dashboards or inefficient
                            Microsoft Access databases. CHC provides a single solution to the disjointed process of collecting evidence and control assessment. A
                            single tool, easy to use method of conducting audits and assessments with a single click report generator. No more copying and pasting
                            hundreds of times to complete a report. CHC built-in intelligence, builds the appropriate charts and tables, then generates a
                            comprehensive and actionable audit and assessment report. CHC can cut the time to conduct an audit/assessment by 50%.</p>
                        </Col>
                        <Col md="6">
                            <img src={img} alt="Cyber Health Check" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
export default CyberHealthCheck